<template>
  <v-container fluid class="fill-height" id="container">
    <v-layout justify-center style="margin-top: -120px;">
      <v-row v-if="APP === 'public'" align="center" justify="center">
        <v-col cols="4" >
          <v-card flat color="transparent" dark>
            <v-card-text class="text-center">
            </v-card-text>
          </v-card>
          <v-card class="elevation-12" flat>
            <v-toolbar color="primary" dark flat>
              <v-toolbar-subtitle><strong>Connection {{ appName }}</strong></v-toolbar-subtitle>
              <v-spacer />
              <v-tooltip bottom v-if="basiAuth">
                <template v-slot:activator="{ on }">
                  <v-btn icon large v-on="on" @click="basiAuth = false">
                    <v-icon>vpn_key</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("login.user_token") }}</span>
              </v-tooltip>
              <v-tooltip right v-else>
                <template v-slot:activator="{ on }">
                  <v-btn icon large v-on="on" @click="basiAuth = true">
                    <v-icon>account_circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("login.basic_auth") }}</span>
              </v-tooltip>
            </v-toolbar>

            <v-card-text class="px-8 py-8">
              <v-alert type="error" v-if="errorMsg">
                {{ errorMsg }}
              </v-alert>
              <form @submit.prevent="onLogin">
                <v-row>
                  <v-col cols="12" xs="12">
                    <v-text-field
                      v-if="basiAuth"
                      prepend-inner-icon="person"
                      outlined
                      color="blue darken-1"
                      name="username"
                      label="Username"
                      type="text"
                      ref="username"
                      v-model="form.username"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12">
                    <v-text-field
                      v-if="!basiAuth"
                      prepend-inner-icon="person"
                      outlined
                      color="blue darken-1"
                      name="userToken"
                      label="User Token"
                      type="text"
                      ref="user_token"
                      v-model="form.userToken"
                      required
                      v-on:keyup.enter="submit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12">
                    <v-text-field
                      v-if="basiAuth"
                      prepend-inner-icon="lock"
                      :append-icon="show ? 'visibility_off' : 'visibility'"
                      outlined
                      color="blue darken-1"
                      name="password"
                      label="Password"
                      :type="show ? 'text' : 'password'"
                      ref="password"
                      v-model="form.password"
                      required
                      @click:append="show = !show"
                      v-on:keyup.enter="submit"
                    ></v-text-field>
                  </v-col>
                  <v-col xs="12" text-xs-center>
                    <v-btn
                      color="primary"
                      block
                      dark
                      @click="submit"
                      :disabled="loading && !formIsValid"
                      :loading="loading"
                    >
                      {{ $t("login.connect") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </v-card-text>
            <v-card-text class="login-footer">
              <p class="text-center mb-0">
                Copyright &copy; {{ appName }} {{ appDate }} v. terminated.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-overlay absolute :value="sessionIsLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-row>
      <v-row v-else align="center" justify="center">
        <v-col cols="6" offset="1">
          <h1 class="display-1">
                <strong>{{ appName }}</strong>
          </h1>
          <h2>
            Cette plateforme est désormais remplacée par sa version 3<br/>
            accessible en vous rendant sur :
          </h2>
          <h2 v-html="newLink"></h2>
          <h2>
            Vous pourrez vous connecter avec vos identifiants habituels.<br/>
          </h2>

          <div class="elevation-1 pa-2 ml-12 mt-12 mr-12">
              Cette nouvelle plateforme vous offre :<br/>
            - plus de rapidité<br/>
            - plus de sécurité<br/>
            - des nouvelles fonctions pour mieux gérer vos séjours<br/>
            - et vos couleurs habituelles<br/>
            Alors n'attendez plus et modifiez vos signets ;) à tout de suite !
          </div>

        </v-col>
        
        <v-overlay absolute :value="sessionIsLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadStates } from "../../utils";
import { PORTAL_CONFIG } from "../../config";

export default {
  data() {
    return {
      drawer: null,
      checkbox: false,
      show: false,
      errorMsg: null,
      basiAuth: true,
      form: {
        username: "",
        password: "",
        userToken: ""
      },
      rules: {
        username: [value => !!value || "Username is required"],
        password: [value => !!value || "Password is required"],
        userToken: [value => !!value || "User Token is required"]
      },
      loading: false,
      appName: "KeepInTouch",
      appDate: new Date().getFullYear()
    };
  },

  computed: {
    ...mapGetters([
      "getSessionLoadState",
      "isAuth",
      "sessionIsInit",
      "getSessionToken"
    ]),
    newLink() {
      return `<a href='${PORTAL_CONFIG.NEW_PLATEFORME}'>${PORTAL_CONFIG.NEW_PLATEFORME}</a>`;
    },
    formIsValid() {
      return this.basiAuth
        ? this.form.username !== "" && this.form.password !== ""
        : this.form.userToken !== "";
    },
    directLogin() {
      return !!this.$route.params.user_token;
    },
    APP() {
      return PORTAL_CONFIG.APP_NAME;
    },

    binding() {
      const binding = {};

      if (this.$vuetify.breakpoint.mdAndUp) {
        binding.row = true;
      } else {
        binding.column = true;
      }

      return binding;
    },
    sessionIsLoading() {
      return this.getSessionLoadState === loadStates.PENDING;
    }
  },

  mounted() {
    document.title = `Connexion - ${this.appName}`;
    if (this.$route.params.user_token) {
      this.form.userToken = this.$route.params.user_token;
      this.loginWithToken();
    }
    if (this.isAuth && !this.sessionIsInit) {
      this.loading = true;
      this.reloadSession(this.getSessionToken).then(() => {
        this.loading = false;
        this.login();
      });
    }
  },

  methods: {
    ...mapActions(["initSession", "login", "loginUser", "reloadSession"]),

    resetForm() {
      Object.keys(this.form).forEach(f => this.$refs[f].reset());
    },

    submit() {
      this.loading = true;
      if (this.basiAuth) {
        this.basicLogin();
      } else {
        this.loginWithToken();
      }
    },
    loginWithToken() {
      this.initSession(this.form.userToken)
        .then(() => {
          this.loading = false;
          this.login();
          this.$router.push("/assets/index");
        })
        .catch(({ response }) => {
          this.loading = false;
          this.errorMsg = response.data[1];
          setTimeout(() => {
            this.errorMsg = null;
          }, 3000);
        });
    },
    basicLogin() {
      this.loginUser(this.form)
        .then(() => {
          this.loading = false;
          this.login();
          this.$router.push("/assets/index");
        })
        .catch(({ response }) => {
          this.loading = false;
          this.errorMsg = response.data[1];
          setTimeout(() => {
            this.errorMsg = null;
          }, 3000);
        });
    }
  }
};
</script>

<style>
#container {
  background: linear-gradient(
    180deg,
    var(--v-primary-base) 0%,
    var(--v-primary-lighten3) 0%,
    #ececec 25%,
    #ececec 100%
  );
}

.login-footer {
  background: #f8f8f8;
  border-top: 1px solid #ececec;
}
</style>
